// Components/Product.js

import React from 'react';
import cyber_banner from '../Assets/cyber_banner.mp4';

function Product() {
  return (
    <div className="product-banner">
      <video autoPlay loop muted className="video-bg">
        <source src={cyber_banner} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="product-content">
        <h1>Create the most beautiful and compelling stories about your cyber threats.</h1>
      </div>
    </div>
  );
}

export default Product;
