import React, { useState } from 'react';
import '../Styles/JoinWaitlistForm.css';

const JoinWaitlistForm = () => {
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [showModal, setShowModal] = useState(false);

  // This function updates the modal and form state accordingly
  const handleFormSubmit = (e) => {
    if (!email.trim() || !company.trim()) {
      alert('Please enter your email address and company name.');
      e.preventDefault(); // Prevent form from submitting
      return;
    }

    console.log('Preparing to submit email and company:', email, company);
    // The actual submission is handled by the form's action attribute
  };

  // Reset form and close modal on manual form submission (if needed)
  const handleReset = () => {
    setEmail('');
    setCompany('');
    setShowModal(false);
    // Optionally, add any success message or redirection here
  };

  return (
    <div className="join-waitlist-container">
      <button className="waitlist-btn" onClick={() => setShowModal(true)}>
        Join Waitlist
      </button>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <h2>Join Our Waitlist</h2>
            <form
              action="https://formspree.io/f/xrgnlloo"
              method="POST"
              onSubmit={handleFormSubmit}
              onReset={handleReset}
              style={{ textAlign: 'left !important' }}
            >
              <input type="hidden" name="_subject" value="Join Waitlist Submission" />
              <label htmlFor="email" style={{ textAlign: 'left' }}>Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your company email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="company" style={{ textAlign: 'left' }}>Company</label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Enter your company name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default JoinWaitlistForm;
