import React from 'react';
import '../Styles/NavBar.css'; // Import the CSS for styling
import JoinWaitlistForm from '../Components/JoinWaitlistForm'; // Import the JoinWaitlistForm component

import Button from '@mui/material/Button'; // Import the Button component

const NavBar = () => {

  const buttonStyle = {
    marginLeft: '10px', // Adjust this value to control space between the elements
    backgroundColor:'#009193'
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <div className="brand-title">CyberScribe</div>
        <ul className="nav-links">
          <li><a href="#info-section-1">Product</a></li>
          <li><a href="#platform-pricing">Pricing</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
        <JoinWaitlistForm /> {/* Move JoinWaitlistForm to the end */}
        <Button variant="contained" style={buttonStyle}>
          Alpha Login
        </Button>
      </div>
    </nav>
  );
};

export default NavBar;
