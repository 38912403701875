import React from 'react';

import cyber_story_image1 from '../Assets/group_comp.JPG'; // Import the image

const InfoSection3 = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    margin: 'auto',
    maxWidth: '800px',
    width: '100%',
    minHeight: '400px',
    flexDirection: 'row',
    boxSizing: 'border-box',
  };

  const textStyle = {
    flex: 1,
    marginRight: '20px', // Adjusted for spacing
    lineHeight: '1.5',
    fontSize: '16px',
  };

  const subHeadingStyle = {
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '20px', // Slightly larger for sub-title
  };

  const imageStyle = {
    width: '400px', // Corrected to include 'px'
    height: '400px',
    borderRadius: '10px',
    objectFit: 'contain',
  };

  return (
    <div style={containerStyle}>
      <div style={textStyle}>
        <div style={subHeadingStyle}>Power in Community</div> {/* Sub-title text */}
        <p>
        Elevate your sharing capabilities with a powerful framework crafted to facilitate effortless dissemination and resharing of insights. Access exquisite email templates and efficient content management tools, complemented by automated sharing functionalities to effortlessly engage with your partners.
        </p>
      </div>
      <img
        src={cyber_story_image1}
        alt="Placeholder"
        style={imageStyle}
      />
    </div>
  );
};

export default InfoSection3;
