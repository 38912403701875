import React from 'react';

import cyber_story_image1 from '../Assets/group_work.JPG'; // Import the image

const InfoSection2 = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    margin: 'auto',
    maxWidth: '800px',
    width: '100%',
    minHeight: '400px',
    flexDirection: 'row',
    boxSizing: 'border-box',
  };

  const textStyle = {
    flex: 1,
    marginLeft: '20px', // Keeps space between text and image
    lineHeight: '1.5',
    fontSize: '16px',
  };

  const subHeadingStyle = {
    fontWeight: 'bold', // Make sub-title bold
    marginBottom: '10px', // Add some space between the sub-title and the paragraph
    fontSize: '20px', // Larger font size for the sub-title
  };

  const imageStyle = {
    width: '300px',
    height: '300px',
    borderRadius: '10px',
    objectFit: 'cover',
  };

  return (
    <div style={containerStyle}>
      <img
        src={cyber_story_image1}
        alt="Placeholder"
        style={imageStyle}
      />
      {/* Wrap sub-title and text in a div that applies textStyle */}
      <div style={textStyle}>
        <div style={subHeadingStyle}>Studio-Grade Content Production</div> {/* Sub-title text */}
        <p>
          Incident Response and Cyber Threat storytellers benefit from specialized tools designed to enhance their storytelling endeavors. Seamlessly edit text and video content with essential features, while maintaining legal-grade document management and tracking for compliance and accountability.
          </p>
      </div>
    </div>
  );
};

export default InfoSection2;
