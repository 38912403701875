import React from 'react';
import '../Styles/MaterialCards.css'; // Ensure the path matches your file structure

import cyber_story_image1 from '../Assets/group1.JPG'; // Import the image
import cyber_story_image2 from '../Assets/group2.JPG'; // Import the image
import cyber_story_image3 from '../Assets/group3.JPG'; // Import the image

const MaterialCards = () => {
  return (
    <div className="material-cards-container">
      {[
        {
          title: "Story Automation",
          description: "From raw SIEM data to compelling story in an instant for you.",
          bullets: [
            "Rapidly create animated videos with built-in tools.",
            "Generate newspaper-quality products instantly."
          ],
          imageUrl: cyber_story_image1
        },
        {
          title: "Story Production",
          description: "Tools tailored for Incident Response and Cyber Threat storytellers.",
          bullets: [
            "Essential editing tools for text and video.",
            "Legal-grade document management and tracking."
          ],
          imageUrl: cyber_story_image2
        },
        {
          title: "Story Sharing",
          description: "The framework you need to share and re-share your insights.",
          bullets: [
            "Beautiful email templates and content management features.",
            "Automated sharing with your partners."
          ],
          imageUrl: cyber_story_image3
        }
      ].map((card, index) => (
        <div className="material-card" key={index}>
          <div className="card-content">
            <h3>{card.title}</h3>
            <p>{card.description}</p>
            <ul>
              {card.bullets.map((bullet, bulletIndex) => (
                <li key={bulletIndex}>{bullet}</li>
              ))}
            </ul>
            {/* Updated to include an image */}
            <img src={card.imageUrl} alt={`${card.title}`} className="card-image" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MaterialCards;
