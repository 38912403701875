// App.js

import React from 'react';
import './App.css';
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';
import Product from './Components/Product';
import InfoSection1 from './Components/InfoSection1';
import InfoSection2 from './Components/InfoSection2';
import InfoSection3 from './Components/InfoSection3';
import Portal from './Components/Portal';
import MaterialCards from './Components/MaterialCards'
import PlatformPricing from './Components/PlatformPricing'


function App() {
  return (
    <div className="App">
      <NavBar />
      <Product />
      <MaterialCards />
      <InfoSection1 />
      <InfoSection2 />
      <InfoSection3 />
      < PlatformPricing/>
      <Portal />
      <Footer />
    </div>
  );
}

export default App;
