import React from 'react';
import JoinWaitlistForm from './JoinWaitlistForm';
import Button from '@mui/material/Button'; // Import the Button component
import Card from '@mui/material/Card'; // Import the Card component
import CardContent from '@mui/material/CardContent'; // Import the CardContent for content inside the Card

const Portal = () => {
  // Updated containerStyle for Card styling
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    margin: 'auto',
    maxWidth: '800px',
    width: '100%',
    flexDirection: 'row',
    boxSizing: 'border-box',
    padding: '20px', // Add some padding inside the card
  };

  // Style for adding space between buttons or elements
  const buttonStyle = {
    marginLeft: '10px', // Adjust this value to control space between the elements
    backgroundColor:'#009193'
  };

  return (
    <Card style={{ maxWidth: '800px', width: '80%', margin: 'auto', marginTop:'100px',backgroundColor:'lightgray' }}> {/* Adjust maxWidth to create a rectangle */}
      <CardContent style={containerStyle}> {/* CardContent for padding and additional styling */}
        <div>
          <JoinWaitlistForm />
        </div>
        {/* Apply the buttonStyle for the Alpha Login Button for spacing */}
        <Button variant="contained" style={buttonStyle}>
          Alpha Login
        </Button>
      </CardContent>
    </Card>
  );
};

export default Portal;
