import React from 'react';

import cyber_story_image1 from '../Assets/group_collab.JPG'; // Import the image

const InfoSection1 = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    margin: 'auto',
    maxWidth: '800px',
    width: '100%',
    minHeight: '400px',
    flexDirection: 'row',
    boxSizing: 'border-box',
  };

  const textStyle = {
    flex: 1,
    marginRight: '20px',
    lineHeight: '1.5',
    fontSize: '16px',
  };

  const subHeadingStyle = {
    fontWeight: 'bold', // Make sub-title bold
    marginBottom: '10px', // Add some space between the sub-title and the paragraph
    fontSize: '20px', // Larger font size for the sub-title
  };

  const imageStyle = {
    width: '300px',
    height: '300px',
    borderRadius: '10px',
    objectFit: 'cover',
  };

  return (
    <div id="info-section-1" style={containerStyle}>
      <div style={textStyle}>
        <div style={subHeadingStyle}>Innovative Reporting Framework</div> {/* Sub-title text */}
        <p>
          Simplify your report production process with CyberScribe, a SaaS platform designed for compliant storytelling. This innovative tool streamlines the creation of well-structured narratives, helping you maintain regulatory compliance while enhancing clarity and efficiency.
        </p>
      </div>
      <img
        src={cyber_story_image1}
        alt="Placeholder"
        style={imageStyle}
      />
    </div>
  );
};

export default InfoSection1;
